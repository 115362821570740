<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="header">{{ baseInfo.examName }}</div>
      <div class="info">
        <span>部门：{{ baseInfo.deptName }}</span>
        <span>姓名：{{ baseInfo.userName }}</span>
        <span>得分：{{ score !== null && score !== void 0 ? score : "--" }}</span>
      </div>

      <a-form :colon="false">
        <div v-for="(element, index) in list" :key="element.type">
          <div class="left title">
            <span style="padding-right: 4px">{{ index + 1 }}.</span>
            <DataDictFinder dictType="exam_question_type" :dictValue="element.type" />
            {{ `（每题${element.score}分，共${element.total}分）` }}
          </div>

          <div class="item" v-for="(item, idx) in element.list" :key="item.id">
            <a-form-item style="margin-bottom: 0">
              <template v-if="item.itemType === 'qa' || item.itemType === 'blank'">
                <div class="subtitle" v-if="item.itemType === 'qa'">
                  <p style="padding-right: 4px">{{ index + 1 }}.{{ idx + 1 }}.</p>
                  <span v-html="item.content"></span>
                </div>
                <div class="subtitle">答题结果：</div>
                <div class="editor—wrapper">
                  <div class="toolbar" :id="'toolbar' + item.id">
                    <!-- 工具栏 -->
                  </div>
                  <div class="editor" :id="'editor' + item.id">
                    <!-- 编辑器 -->
                  </div>
                </div>
              </template>

              <div class="subtitle" v-else>
                <p style="padding-right: 4px">{{ index + 1 }}.{{ idx + 1 }}.</p>
                <span v-html="item.content"></span>
              </div>

              <div class="subtitle" v-if="item.itemType === 'terms'">
                答题结果：
                {{ item.userAnswer }}
              </div>

              <div class="subtitle" v-if="item.answer">
                答题结果/正确答案：{{
                Array.isArray(item.userAnswer)
                ? item.userAnswer.join(",")
                : item.userAnswer
                }}/{{ item.answer }}
                <template
                  v-if="typeof item.userAnswer === 'string'"
                >
                  (
                  <span v-if="item.userAnswer === item.answer" style="color: green">正确</span>
                  <span class="danger" v-else>错误</span>)
                </template>
                <template v-if="Array.isArray(item.userAnswer)">
                  (
                  <span v-if="item.userAnswer.join(',') === item.answer" style="color: green">正确</span>
                  <span class="danger" v-else>错误</span>)
                </template>
              </div>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </a-card>

    <a-card class="container">
      <div class="score-title">考试得分</div>
      <a-input-number
        style="width: 240px; margin-bottom: 16px"
        placeholder="请输入分数"
        v-model="score"
      />
      <div>
        <a-space>
          <a-button type="primary" @click="done" :loading="saveLoading" :disabled="disabled">打分</a-button>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>


<script>
import watermark from "@/mixins/watermark";
import { fetchDetail, fetchUserAnswer, mark } from "@/api/exam";

import "@wangeditor/editor/dist/css/style.css";
import { createEditor, createToolbar } from "@wangeditor/editor";

export default {
  mixins: [watermark],

  data() {
    return {
      baseInfo: {},
      detail: {},
      id: null,
      list: [], // 题目列表

      saveLoading: false,

      form: this.$form.createForm(this),
      activeType: "",
      visible: false,

      score: null,
      disabled: false
    };
  },

  mounted() {
    const { query } = this.$route;

    const { id, examId, examName, userId, userName, deptId, deptName } =
      query || {};
    if (id && examId && examName && userId && userName && deptId && deptName) {
      this.baseInfo = query;
      this.getDetail();
    } else {
      this.$message.error("URL不正确！");
    }
  },

  beforeDestroy() {
    // 销毁编辑器
    this.editorList?.forEach(item => {
      item.editor.destroy();
    });
  },

  methods: {
    getDetail() {
      fetchDetail({
        id: this.baseInfo.examId
      }).then(res => {
        if (Array.isArray(res.examItemList)) {
          const types = [
            ...new Set(res.examItemList.map(item => item.itemType))
          ];

          this.list = types.map(type => {
            const list = [];
            res.examItemList.forEach(item => {
              if (item.itemType === type) {
                list.push(item);
              }
            });
            let total = 0;
            let score = 0;
            list.forEach(item => {
              total += item.score;
              score = item.score;
            });
            return {
              type,
              list,
              total,
              score
            };
          });

          this.getAnswer();
        }
      });
    },

    getAnswer() {
      fetchUserAnswer({
        examId: this.baseInfo.examId,
        userId: this.baseInfo.userId
      }).then(res => {
        if (res && Array.isArray(res.examUserItemVoList)) {
          this.list = this.list.map(e => {
            return {
              ...e,
              list: e.list.map(item => {
                const obj = res.examUserItemVoList.find(
                  element => element.itemId === item.id
                );
                let userAnswer = obj && obj.userAnswer ? obj.userAnswer : "";

                if (
                  obj &&
                  obj.itemType === "checkbox" &&
                  userAnswer.indexOf(",") > -1
                ) {
                  userAnswer = userAnswer.split(",");
                }
                return obj
                  ? {
                      ...item,
                      userAnswer
                    }
                  : item;
              })
            };
          });

          this.score = res.score;

          const that = this;
          this.$nextTick().then(function() {
            that.initEditors(that.list);
          });
        }
      });
    },

    initEditors(list) {
      const arr = [];
      list.forEach(element => {
        element.list.forEach(item => {
          if (item.itemType === "qa" || item.itemType === "blank") {
            const editorConfig = {
              placeholder: "",
              hoverbarKeys: {
                formula: {
                  menuKeys: ["editFormula"] // “编辑公式”菜单
                }
              }
            };

            const editor = createEditor({
              selector: "#editor" + item.id,
              html: item.userAnswer,
              config: editorConfig,
              mode: "default" // or 'simple'
            });

            const toolbarConfig = {
              toolbarKeys: []
            };

            createToolbar({
              editor,
              selector: "#toolbar" + item.id,
              config: toolbarConfig,
              mode: "default" // or 'simple'
            });

            editor.disable();

            arr.push({
              id: item.id,
              editor
            });
          }
        });
      });
      this.editorList = arr;
    },

    done() {
      if (this.score === null || this.score === void 0) {
        this.$message.error("请打分！");
        return;
      }
      this.saveLoading = true;

      mark({
        examUserMarkBase: {
          ...this.baseInfo,
          score: this.score
        }
      })
        .then(() => {
          setTimeout(() => {
            this.saveLoading = false;
            this.$router.go(-1);
          }, 1000);
        })
        .catch(() => {
          this.saveLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;

  .header {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 8px;
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    span {
      padding: 0 4px;
    }
  }
}

.title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 12px;
}
.item {
  .subtitle {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.8em;
    margin-bottom: 8px;
    display: flex;
    align-items: baseline;
  }
}

.score-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 16px;
}

.editor—wrapper {
  border: 1px solid #e0e0e0;
  z-index: 100; /* 按需定义 */
  margin-bottom: 12px;
}

.editor {
  height: 300px;
}
</style>


<style>
p {
  margin-bottom: 4px;
}
table,
th,
td {
  border: 1px solid #e0e0e0;
}
th,
td {
  padding: 8px;
}
</style>